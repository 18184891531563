import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { RoundPipe } from './round.pipe';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about.component';
import { ResultsComponent } from './results.component';

const appRoutes: Routes = [
  { path: '', component: ResultsComponent },
  { path: 'about', component: AboutComponent },
  { path: '**', component: ResultsComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ResultsComponent,
    RoundPipe
  ],
  imports: [
    BrowserModule,
    HttpModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

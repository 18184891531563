import { Component, OnInit, Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { ApiResponse } from './apiresponse';
import { RoundPipe } from './round.pipe';

@Component({
  selector: 'app-root',
  templateUrl: './about.component.html',
  // styleUrls: ['./about.component.css']
})
export class AboutComponent {
  title = 'SmileRank - About';
  data = {};

}

import { Component, OnInit, AfterViewChecked, Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { ApiResponse } from './apiresponse';
import { RoundPipe } from './round.pipe';
declare var $;

@Component({
  selector: 'app-root',
  templateUrl: './results.component.html',
  // styleUrls: ['./app.component.css']
})
export class ResultsComponent implements OnInit, AfterViewChecked {
  url = 'https://api.smilerank.com/?m=api&vote={"method":"get"}';
  constructor(private http:Http) { 
    this.http = http;
  }

  title = 'SmileRank';
  data = {};
  
  ngOnInit(): void {
    var self = this;

    self.loadData();
    window.setInterval(function () {
      self.loadData();
    }, 1000 * 60)
  };

  public ngAfterViewChecked(): void {
    $("tbody").trSorter({
      filterObj: "thead th",
      rows: 'tr',
      event: 'click',
      getSortValue: function (rowObj, filterObj) {
        var key = filterObj.data('sortkey');
        var val = rowObj.data(key);

        if (val == undefined) {
          val = rowObj.find("." + key).html();
        }

        return val.toString().toUpperCase();
      },
    });
  } 

  loadData(): void {
    var self = this;

    self.http.get(self.url)
      .pipe(
        map(res => <ApiResponse>res.json())
      ).subscribe(data => {
        data.yourCountryHappyness = 100;
        data.countries.forEach(function (country) {
          if (country.Country == data.yourCountry) {
            data.yourCountryHappyness = country.YesPercentage;
          }
        })        
        self.data = data;
      });
  }

}
